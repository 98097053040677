.wizard {
  @include media-breakpoint-up(lg) {
    //margin: 0 60px;
    display: flex;
  }
  .alert-success {
    background: white;
  }
}
.wizard-container {
  margin-top: $nav-link-height * 2;
  min-height: 450px;
}
.wizard-toolbar {
  padding: 0 $grid-gutter-width / 2;
  background: white;
  display: flex;
  border-bottom: 1px solid $border-color;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  right: 0;
  left: $sidebar-max-width;
  z-index: 1000;
  .wizard-actions {
    display: flex;
    align-items: center;
  }
  .navbar {
    padding: 0;
    min-height: 46px;

  }

}
.wizard-toolbar-top {
  top: $nav-link-height;
  padding-top: 2px;
  height: $nav-link-height * 1.25;
  align-items: center;
  h1 {
    font-size: 1.8rem;
    margin-bottom: 0;
  }
}
.wizard-toolbar-bottom {
  top: $nav-link-height * 1.25 + $nav-link-height;
  //height: $nav-link-height;
}

.wizard-ui {
  @include media-breakpoint-up(lg) {
    width: 60%;
  }
  @include media-breakpoint-up(xl) {
    width: 50%;
  }
  margin-bottom: 2rem;
}

.wizard-output {
  position: sticky;
  top: 10.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  margin: 0 $grid-gutter-width / 2;
  @include media-breakpoint-up(lg) {
    //margin: 0 60px;
    width: 50% !important;
  }
}
.wizard-preview-wrapper {
  //position: relative;
  //border: 1px solid blue;
  margin-bottom: 1rem;
  //transition: all 0.25s;

}
.wizard-preview-zoom {
  display: flex;
  input {
    margin: 0 0.5rem;
  }
}
.wizard-preview {
  position: relative;
  //transition: all 0.25s;
  //top: 0;
  //left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 0 24px 2px rgba(0,0,0,0.5);
  overflow: hidden;
  * {
    user-select: none;
  }
  background-color: white;
  .grid-lines {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
  .grid-lines-h {
    .grid-line {
      border-bottom: 1px solid $gray-500;
      position: absolute;
      width: 100%;
    }
    .grid-line-half {
      border-bottom: 1px dotted $gray-500;
    }

  }
  .grid-lines-v {
    .grid-line {
      border-right: 1px solid $gray-500;
      position: absolute;
      height: 100%;
    }
    .grid-line-half {
      border-right: 1px dotted $gray-500;
    }

  }
  .grid-line-1 {

  }
  .userText-preview {
    overflow: hidden;
    height: 100%;
  }
  //&.portrait-tall {
  //  transform-origin: top center;
  //  transform: scale(0.33);
  //}

}
.wizard-preview-layer-wrapper {
  position: absolute;
}
.wizard-preview-wrapper-inner {

}

.wizard-preview-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img,
  .img {
    position: absolute;
    max-width: 100%;
    height: auto;
  }
  p {
    margin: 0;
    br:first-child {
      &:after {
        content: "break";
      }
    }
  }
}

.wizard-preview-layer-draggable {
  &.active {
    box-shadow: inset 0 0 8px 0 rgba(0,0,0,0.25);

  }
  .vdr {
    border: none;
    &.active {
      border: 1px dashed gray;
    }
  }
}

.wizard-actions {
  //min-width: 360px;
}

.modal-body {
  .submitting {
    filter: blur(2px);
    //filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask");
    z-index: 100;
  }
  .modal-status {
    z-index: 200;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(white, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;


  }
}

.wizard-section-header {
  font-size: $font-size-sm;
  h2 {
    font-size: 1.5rem;
    font-weight: normal;
  }
  margin-bottom: 1rem;
}

.wizard-options {
  margin-bottom: 1rem;
  img {
    display: block;
    //margin: 3px;
  }

  button.btn-image {
    cursor: pointer;
    background: transparent;
    padding: 3px;
    border: 1px solid $gray-200;
    max-width: 100%;

  }

  .selected {
    position: relative;

    &:after {
      //box-shadow: inset 2px 2px 10px 1px rgba(black, 0.6);
      box-shadow: inset 0 0 0 3px theme-color("primary");
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    //box-shadow: 0 0 0 3px $body-color inset;

  }
}

.wizard-option {
  //text-align: center;

  float: left;
  width: 32%;
  margin-right: 1%;
  margin-bottom: 1%;
  @supports (display:grid) {
    width: auto;
    margin: 0;
  }
}

.wizard-options-headline,
.wizard-options-bodyCopy {
  button {
    img {
      padding: 0.5rem;
    }
  }
}

.wizard-options-headline .wizard-section-body,
.wizard-options-bodyCopy .wizard-section-body {
  overflow: hidden;
  .wizard-option {
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }
}

.wizard-options-image .wizard-section-body {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @include media-breakpoint-up(lg) {
    //margin: 0 60px;
    //width: 50% !important;
  }
  grid-gap: 1rem;
}


.wizard-options-logo .wizard-section-body {
  @include media-breakpoint-up(xl) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

}

.quill-editor {
  background: white;
}