//@import "~bootstrap/scss/bootstrap";
@import "variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";


@import '~baguettebox.js/dist/baguetteBox.min.css';
//@import '~@fortawesome/fontawesome-free/scss/fontawesome';

.btn {
  margin-right: 0.5rem;
}

.gallery {
  .gallery-item {
    img {

    }
    .item-actions {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background: rgba(black, 0.65);
      color: white;
      padding: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      transform: translateY(-100%);
      transition: all 0.25s ease-in-out;
      a {
        color: white;
        font-size: 1.5rem;
      }
    }
    &:hover {
      .item-actions {
        transform: translateY(0);

      }
    }
    .item-footer {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(black, 0.65);
      color: white;
      padding: 0.5rem;
      font-size: 0.8rem;
    }
  }
}

.bricks {
  .gallery-item-wrap {
    width: 240px;
  }

  .gallery-item {
    box-shadow: 0px 0 1px black;
    height: 0;
    position: relative;

  }
}

.justified {
  .gallery-item {

  }
}

@import "~justifiedGallery/dist/css/justifiedGallery.css";

//@import 'list-group';
@import 'card';
@import 'header';
@import 'sidebar';
@import 'wizard';
@import 'facets';
@import 'media';
@import 'upvote';
@import 'analytics';

.main {
  padding-top: $main-offset-top;
  width: 100%;
}




.thumbnail {
  position: relative;
  .delete-button {
    display: none;
    position: absolute;
    top: 4px;
    right: 4px;
    //background: $primary-color;
    color: white;
    //padding: $badge-padding;
  }
  &:hover {
    .delete-button {
      display: block;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.member-list {
  .member-list-item {
    //border-bottom: 1px solid $gray-200;
    //padding: 0.5rem 0;
    //position: relative;
    //&:last-child {
    //  border-bottom: none;
    //}
    .edit {
      display: none;
      position: absolute;
      right: 0;
      top: 4px;
    }
    &:hover {
      .edit {
        display: block;
      }
    }
    .member-edit {
      padding-top: 0.5rem;
    }
  }
}

.main {
  margin-left: $sidebar-max-width;
}

.site-footer {
  padding-left: $sidebar-max-width;


  .site-footer-inner {
    font-size: 8px;
    margin-top: 2rem;
    border-top: 1px solid $gray-400;

  }
}

.thumbnail-boxed {
  border: 1px solid $gray-400;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.appWrap {
  min-height: 800px;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 3px;
}

.nav-pills-sm {
  .nav-link {
    padding: 0.25rem 1rem;
  }
}
